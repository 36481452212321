import React from 'react'

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p>&copy; Mobile TK Brazil 2020. All Rights Reserved.</p>
      </div>
    </footer>
  )
}
